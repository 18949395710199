<template>

  <div class="service_form_details">
    <b-row
      class="content-header create-edit-form"
    >

      <b-col
        cols="md-8"
        class="mb-1"
      >
        <h1 class="custom-header-title">
          {{ vendor.stringID }} - {{ vendor.companyName }}
        </h1>
        <p class="log_info">
          Created by {{ vendor.createdBy ? vendor.createdBy.name : '' }} on {{ dateFormatWithTime(vendor.createdAt) }}<br>Last updated on {{ dateFormatWithTime(vendor.updatedAt) }}
        </p>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right mb-1"
        cols="md-4"
      >
        <div class="user_block ac_type">
          <div class="user_avatar" />
          <div class="user_info">
            <h6>Status</h6>
            <h5 class="text-capitalize">
              {{ vendor.status ? vendor.status.replace("-", " ") : '' }}
            </h5>
          </div>
          <!-- <div class="action_button">
            <b-button
              v-b-modal.vendor-all-modal-update-status
              variant="flat-primary"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
                size="16"
              />
            </b-button>
          </div> -->
        </div>
      </b-col>
    </b-row>
    <!-- Table Container Card -->

    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left booking_content_wrapper mb-2"
        cols="12"
        md="12"
      >
        <b-dropdown
          v-if="canViewThisAction('update', 'Vendor')"
          variant="outline-primary"
          no-caret
          class="text-right ml-auto float-right status-single-dropdown"
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="MoreHorizontalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <!-- <b-dropdown-item
            v-if="canViewThisAction('update', 'Vendor') && vendor.status == 'draft' && vendor.createdBy && vendor.createdBy._id == user._id"
            v-b-modal.vendor-all-modal-update-status
          >
            <span class="align-middle">Update Vendor Status</span>
          </b-dropdown-item>
          <b-dropdown-item
            v-else-if="canViewThisAction('update', 'Vendor') && vendor.status != 'draft'"
            v-b-modal.vendor-all-modal-update-status
          >
            <span class="align-middle">Update Vendor Status</span>
          </b-dropdown-item> -->
          <b-dropdown-item
            v-if="canViewThisAction('update', 'Vendor') && vendor.status != 'draft'"
            v-b-modal.vendor-all-modal-update-status
          >
            <span class="align-middle">Update Vendor Status</span>
          </b-dropdown-item>

          <div v-if="canViewThisAction('create', 'Vendor')">
            <b-dropdown-item
              v-if="vendor.status == 'draft' || vendor.status == 'incoming'"
              @click="generateOnlineLink(vendor._id)"
            >
              <span class="align-middle">Create an online link for this Vendor</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-else
              disabled
            >
              <span class="align-middle">Create an online link for this Vendor</span>
            </b-dropdown-item>
          </div>

          <b-dropdown-item
            v-if="canViewThisAction('update', 'Vendor')"
            :to="{ name: 'purchasing-vendors-edit', params: { id: vendor._id ? vendor._id : 0 } }"
          >
            <span class="align-middle">Edit Vendor</span>
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>

    <b-card
      class="mb-3 p-2 calllog-details serviceForm_details"
    >
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
        >
          <h3>Company General Information</h3>
        </b-col>
        <b-col
          class="content-header-right text-md-right d-md-block d-none mb-1"
          md="3"
          cols="12"
        >
          <b-button
            v-if="canViewThisAction('update', 'Vendor')"
            variant="flat-primary"
            @click="confirmBeforeEdit('company_detail')"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th width="230px">
              Registration No.
            </b-th>
            <b-td>{{ vendor.companyRegistration ? vendor.companyRegistration : '-' }}</b-td>
            <b-th width="230px">
              Tel
            </b-th>
            <b-td>{{ vendor.companyContact ? vendor.companyContact : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              GST Registration No.
            </b-th>
            <b-td>{{ vendor.companyGSTRegistration ? vendor.companyGSTRegistration : '-' }}</b-td>
            <b-th width="230px">
              Fax
            </b-th>
            <b-td>{{ vendor.companyFax ? vendor.companyFax : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              NRIC No. (For Individual)
            </b-th>
            <b-td>{{ vendor.individualNRIC ? vendor.individualNRIC : '-' }}</b-td>
            <b-th width="230px">
              Address
            </b-th>
            <b-td>{{ vendor.companyAddress ? vendor.companyAddress : '-' }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <hr class="dividerHR">
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
        >
          <h3>Sales Person Information</h3>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th style="width:360px">
              Name
            </b-th>
            <b-td>{{ vendor.salesPersonName ? vendor.salesPersonName : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th style="width:360px">
              Contact No.
            </b-th>
            <b-td>{{ vendor.salesPersonContact ? vendor.salesPersonContact : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th style="width:360px">
              Email Address
            </b-th>
            <b-td>{{ vendor.salesPersonEmail ? vendor.salesPersonEmail : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th style="width:360px">
              Email Address 2
            </b-th>
            <b-td>{{ vendor.salesPersonEmail2 ? vendor.salesPersonEmail2 : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th style="width:360px">
              Any Related Family Parties Working In NV Group?
            </b-th>
            <b-td class="text-capitalize">
              {{ vendor.relativeInNirvana ? vendor.relativeInNirvana : '-' }}
              <br>
              <span v-if="vendor.relativeInNirvana == 'yes'"> {{ vendor.relativeDetail ? vendor.relativeDetail : '-' }}</span>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <hr class="dividerHR">

      <b-row
        class="content-header"
      >
        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
        >
          <h4>
            <span class="align-middle">Product / Service Provided and Payment Terms</span>
          </h4>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th style="width:360px">
              Type of Product/Service Provided
            </b-th>
            <b-td>{{ vendor.providedServiceType ? vendor.providedServiceType : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th style="width:360px">
              Payment Terms
            </b-th>
            <b-td>{{ resolvePaymentTerms(vendor.paymentTerms) }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <hr class="dividerHR">

      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
        >
          <h3>Banking Information</h3>
        </b-col>
        <b-col
          class="content-header-right text-md-right d-md-block d-none mb-1"
          md="3"
          cols="12"
        >
          <b-button
            v-if="canViewThisAction('update', 'Vendor')"
            variant="flat-primary"
            @click="confirmBeforeEdit('banking_detail')"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th width="230px">
              Account Payee Name
            </b-th>
            <b-td>{{ vendor.accountPayeeName ? vendor.accountPayeeName : '-' }}</b-td>
            <b-th width="230px">
              Bank Account No.
            </b-th>
            <b-td>{{ vendor.bankAccountNo ? vendor.bankAccountNo : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Bank Name
            </b-th>
            <b-td>{{ vendor.bankName ? vendor.bankName : '-' }}</b-td>
            <b-th width="230px">
              Address
            </b-th>
            <b-td>{{ vendor.bankAddress ? vendor.bankAddress : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Bank Branch
            </b-th>
            <b-td>{{ vendor.bankBranch ? vendor.bankBranch : '-' }}</b-td>
            <b-th width="230px">
              Email Address
            </b-th>
            <b-td>{{ vendor.bankEmail ? vendor.bankEmail : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Swift Code (For Overseas)
            </b-th>
            <b-td>{{ vendor.swiftCode ? vendor.swiftCode : '-' }}</b-td>
            <b-th width="230px">
              Paynow Details
            </b-th>
            <b-td>{{ vendor.paynowNo ? vendor.paynowNo : '-' }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <hr class="dividerHR">

      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
        >
          <h3>Certifications Obtained</h3>
        </b-col>
        <b-col
          class="content-header-right text-md-right d-md-block d-none mb-1"
          md="3"
          cols="12"
        >
          <b-button
            v-if="canViewThisAction('update', 'Vendor')"
            variant="flat-primary"
            @click="confirmBeforeEdit('certification_detail')"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th width="230px">
              Certifications
            </b-th>
            <b-td
              class="certificate_col"
            >
              <span
                v-for="(certificate, key) in vendor.certificates"
                :key="key"
                class="content-header-left mb-2"
                cols="12"
                md="4"
              >
                <span v-if="certificate == 'Others'">
                  {{ vendor.otherCertificates }}
                </span>
                <span v-else>
                  {{ certificate }}
                </span>
              </span>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <hr class="dividerHR">

      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
        >
          <h3>Attachments</h3>
        </b-col>
      </b-row>
      <b-row>
        <!-- Content Left -->
        <b-col
          v-for="(file, key) in vendor.attachments"
          :key="key"
          class="content-header-left mb-2"
          cols="12"
          md="4"
        >
          <div
            class="attachment-item"
          >
            <b-img
              v-if="file.type.includes('image')"
              left
              height="40"
              :src="require('@/assets/images/admin/doc_jpg.png')"
              alt="Left image"
            />
            <b-img
              v-if="file.type.includes('application')"
              left
              height="40"
              :src="require('@/assets/images/admin/doc_file.png')"
              alt="Left image"
            />
            <strong>
              <b-link
                :href="file ? file.data : ''"
                target="_blank"
              >
                {{ file ? file.name : '' }}
              </b-link>
            </strong>
            <span>{{ file ? `${file.size}mb` : '' }}</span>
            <!-- <span class="remove__doc">
              <b-button
                variant="flat-primary"
                @click="removeAttachment(file.data)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-50"
                  size="18"
                />
              </b-button>
            </span> -->
          </div>
          <div class="doc__desc">
            <span v-if="file.description">
              {{ file.description }}
            </span>
            <span v-else>
              <button @click="updateDescription(key)">Add a description</button>
            </span>
            <span class="edit__desc">
              <b-button
                v-if="file.description"
                variant="flat-primary"
                @click="updateDescription(key)"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                  size="18"
                />
              </b-button>
            </span>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <div class="without__bg">
      <div class="signatories_block pt-2">
        <b-row class="content-header">
          <b-col
            v-for="(signatory, index) of vendor.signatories"
            :key="index"
            cols="3"
            md="3"
            class="mt-md-0 mt-3"
            order="2"
            order-md="1"
          >
            <b-card-text class="mb-0">
              <span class="font-weight-bold">{{ index + 1 }}. {{ signatory.type }}</span>
              <div class="sign-box">
                <div v-if="signatory.user">
                  <b-avatar
                    v-if="signatory.user.image"
                    :src="signatory.user.image"
                  />
                  <b-avatar
                    v-else
                    :text="resolveAcronymName(signatory.user.name)"
                  />
                </div>
                <div v-if="signatory.signed == true">
                  <!-- <div v-if="signatory.user && signatory.user.signature">
                    <b-img
                      class="mb-1 mb-sm-0 user__avatar"
                      height="50"
                      :src="signatory.user.signature"
                    />
                  </div> -->
                  <div v-if="signatory.user && signatory.user.name">
                    <span>{{ signatory.user.name }}</span>
                  </div>
                </div>
                <div v-else>
                  <p>Pending Signature</p>
                </div>
              </div>
              <p class="ml-75 semi-bold">
                {{ signatory.user ? signatory.user.name : '' }}
              </p>
              <p class="ml-75">
                {{ signatory.user ? (signatory.user.department.length ? signatory.user.department[0].name : '') : '' }}
              </p>
              <p class="ml-75">
                {{ signatory.signed == true ? dateFormatWithTime(signatory.date) : '-' }}
              </p>
            </b-card-text>
          </b-col>
        </b-row>
      </div>
    </div>

    <b-nav
      v-if="canApprove"
      class="wrap-border save-nav"
    >
      <li
        class="nav-item ml-auto"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          variant="flat-primary"
          :to="{ name: 'purchasing-vendors-index', params: { type: 'all-vendors' } }"
        >
          Cancel
        </b-button>
      </li>
      <li
        class="nav-item mr-1"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          variant="success"
          :to="{ name: 'purchasing-vendors-preview', params: { id: $route.params.id } }"
        >
          <feather-icon
            icon="EyeIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ previewButtonText }}</span>
        </b-button>
      </li>
    </b-nav>

    <b-modal
      id="vendor-all-modal-update-status"
      ref="update-status-modal"
      cancel-variant="flat-primary"
      modal-class="status-update-modal"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Status"
      @ok="submitForm"
    >
      <b-form @submit.prevent="submitForm">
        <validation-observer
          ref="allvendorStatusEditForm"
        >
          <b-form-group
            label="Status*"
            label-for="h-event-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-event-status"
                v-model="status"
                label="title"
                placeholder="Please Select The Option"
                :options="statusDataOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="statusValidation == true ? statusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="statusValidation"
                class="text-danger"
              >
                {{ statusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>
    <b-modal
      id="modal-attachment-description"
      size="lg"
      title="Attachment Description"
      modal-class="status-update-modal"
      centered
      no-stacking
      header-class="front-modal"
      @ok="saveDescription"
      @cancel="closeDescriptionModal"
    >
      <b-row>
        <b-col md="12">
          <b-form-textarea
            id="h-customer-phone-number"
            v-model="description"
            placeholder="E.g. Please remind to make payment"
          />
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="!description || attachmentKey === ''"
          @click="ok()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BButton, BCol, BTableSimple, BTbody, BTr, BTh, BTd, BForm, BFormGroup, BImg, BFormTextarea, BLink, BCardText,
  BAvatar, BNav, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import store from '@/store'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BForm,
    BFormGroup,
    BImg,
    BFormTextarea,
    BLink,
    BCardText,
    BAvatar,
    BNav,
    BDropdown,
    BDropdownItem,

    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      canViewThisAction,
      user: store.state.auth.userData,
      vendor: {},
      vendorSignatories: [],
      vendorID: '',
      stringID: '',
      status: '',
      previewButtonText: '',
      statusValidation: false,
      canApprove: false,
      statusError: 'Valid status is required',
      statusDataOptions: [
        { title: 'Draft', code: 'draft' },
        { title: 'Non Approved', code: 'non approved' },
      ],
      description: '',
      attachmentKey: '',
      required,
      emailToOptions: [],
      lqas: [
        '1. Prepared by',
        '2. Requested by',
        '3. Verified by',
        '4. Recommended by',
        '5. Agreed by',
        '6. Certified by',
        '7. Confirmed by',
        '8. Supported by',
        '9. Validated by',
        '10. Approved by',
      ],
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
    }
  },
  mounted() {
    this.$http.get(`purchase/vendors/${this.$route.params.id}/show`)
      .then(response => {
        this.vendor = response.data.data || {}
        this.vendorSignatories = response.data.vendorSignatories || []

        this.setData()
        this.resolveSignatory(this.vendor.signatories)
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    closeDescriptionModal() {
      this.$root.$emit('bv::hide::modal', 'modal-attachment-description', '')
    },
    updateDescription(key) {
      this.attachmentKey = key
      this.description = this.vendor.attachments[key].description
      this.$root.$emit('bv::show::modal', 'modal-attachment-description', '')
    },
    generateOnlineLink(id) {
      const link = `${window.location.origin}/vendor/self-registration/${id}?referrer=${this.user._id}`
      this.$swal({
        title: 'Link Generated',
        html: `The vendor can now fill up the New Vendor Registration Form by accessing this link below: <br> ${link}`,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/link.png'),
        showCancelButton: false,
        confirmButtonText: 'Copy Link',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        allowOutsideClick: false,
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$copyText(link).then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Text copied',
                  icon: 'BellIcon',
                },
              })
            }, () => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Can not copy!',
                  icon: 'BellIcon',
                },
              })
            })
          }
        })
    },
    resolvePaymentTerms(val) {
      let text = ''
      if (val === 'Others') {
        text = this.vendor.paymentTermsDescription
      } else {
        text = `${val}`
      }

      return text
    },
    resolveSignatory(signatories) {
      if (signatories) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < signatories.length; i++) {
          if (signatories[i].user._id === this.user._id) {
            if (i === 0 && signatories[i].signed === false) {
              this.canApprove = true
              this.previewButtonText = 'Preview Before Submitting'
              break
            }
            if (i > 0 && signatories[i].signed === false && signatories[i - 1].signed === true) {
              this.canApprove = true
              this.previewButtonText = 'Preview Before Approving'
              break
            }
          }
        }
      }

      return true
    },
    confirmBeforeEdit(section) {
      this.$swal({
        title: 'Are You Sure?',
        html: 'Editing this vendor will reset the approval process and require all signatories to confirm and sign once again.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, I am sure',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$router.push({ name: 'purchasing-vendors-edit', params: { id: this.$route.params.id }, query: { section } })
          }
        })
    },
    approveVendor() {
    //   let firstSwalMessage = ''
    //   let secondSwalMessage = ''
    //   let secondSwalTitle = 'QCF Signed'
    //   if ((this.quotation.signatories.length - 1) === index) {
    //     firstSwalMessage = 'You are proceeding to sign this QCF. Following this, Central Purchasing start the PO process.'
    //     secondSwalTitle = 'QCF Approved'
    //     secondSwalMessage = `${this.purchaseRequest.caseID} is now ready for Purchase Order creation by Central Purchasing.`
    //   } else {
    //     firstSwalMessage = `You are proceeding to sign on this QCF. The QCF will then be sent to ${this.quotation.signatories[index + 1] ? this.quotation.signatories[index + 1].user.name : ''} for signing.`
    //     secondSwalMessage = `${this.purchaseRequest.caseID} will now be sent to the next assigned signatory (${this.quotation.signatories[index + 1] ? this.quotation.signatories[index + 1].user.name : ''}) for signing.`
    //   }
    //   // this.previewPage = true
    //   this.$swal({
    //     title: 'Sign this QCF?',
    //     html: firstSwalMessage,
    //     // eslint-disable-next-line global-require
    //     imageUrl: require('@/assets/images/icons/fly.png'),
    //     reverseButtons: true,
    //     showCancelButton: true,
    //     cancelButtonText: 'No, Cancel',
    //     confirmButtonText: 'Yes, Proceed!',
    //     customClass: {
    //       confirmButton: 'btn btn-primary ml-1',
    //       cancelButton: 'btn btn-outline-primary',
    //     },
    //     buttonsStyling: false,
    //   })
    //     .then(result => {
    //       if (result.value) {
    //         const formData = new FormData()
    //         formData.append('id', id)
    //         formData.append('index', index)
    //         formData.append('signatoryRequired', this.quotation.signatoryRequired)
    //
    //         this.$http.post(`purchase/purchase-request/${this.$route.params.id}/approve-qcf/${this.quotation._id}`, formData, {
    //           headers: { 'Content-Type': 'multipart/form-data' },
    //         })
    //           .then(response => {
    //             this.$emit('update:purchaseRequest', response.data.data)
    //             this.quotation = response.data.qcf
    //             this.$swal({
    //               title: secondSwalTitle,
    //               html: secondSwalMessage,
    //               // eslint-disable-next-line global-require
    //               imageUrl: require('@/assets/images/icons/success.png'),
    //               imageWidth: 80,
    //               imageHeight: 80,
    //               imageAlt: 'Custom Icon',
    //               showCancelButton: false,
    //               confirmButtonText: 'Okay',
    //               customClass: {
    //                 confirmButton: 'btn btn-primary',
    //               },
    //               buttonsStyling: false,
    //             })
    //           })
    //           .catch(error => {
    //             this.$toast({
    //               component: ToastificationContent,
    //               props: {
    //                 title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
    //                 icon: 'AlertTriangleIcon',
    //                 variant: 'danger',
    //               },
    //             })
    //           })
    //       }
    //     })
    },
    removeAttachment(url) {
      this.$http
        .post('purchase/vendors/remove-attachment', { url, vendor: this.$route.params.id })
        .then(response => {
          this.vendor = response.data.data || {}
          this.setData()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    saveDescription() {
      const url = this.vendor.attachments[this.attachmentKey].data
      this.$http
        .post('purchase/vendors/save-description', { url, description: this.description, vendor: this.$route.params.id })
        .then(response => {
          this.vendor.attachments = response.data.data.attachments || {}
          // this.setData()
          this.closeDescriptionModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    setData() {
      this.vendorID = this.vendor._id
      this.stringID = this.vendor.stringID
      if (this.vendor.status === 'pending') {
        this.statusDataOptions = [
          { title: 'Draft', code: 'draft' },
          { title: 'Non Approved', code: 'non approved' },
        ]
      } else if (this.vendor.status === 'approved') {
        this.statusDataOptions = [
          { title: 'Draft', code: 'draft' },
          { title: 'Non Approved', code: 'non approved' },
        ]
      } else if (this.vendor.status === 'non approved') {
        this.statusDataOptions = [
          { title: 'Draft', code: 'draft' },
        ]
      } else if (this.vendor.status === 'draft') {
        this.statusDataOptions = [
          { title: 'Pending', code: 'pending' },
        ]
      }
    },
    resolveAcronymName(name) {
      return name.split(' ').map(item => item.charAt(0)).join('').toUpperCase()
    },
    submitForm(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.allvendorStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.status)

          this.$http.patch(`purchase/vendors/${this.vendorID}/status/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.vendor.status = response.data.data.status || 'confirmed'
              this.$root.$emit('bv::hide::modal', 'vendor-all-modal-update-status', '')
              this.setData()
              this.$swal({
                title: 'Status Updated!',
                html: `The status for <strong>${this.stringID}</strong> has been updated`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
                .then(() => {
                  this.$router.push({ name: 'purchasing-vendors-index', params: { type: 'all-vendors' } })
                })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    resolveOptionValue(val) {
      if (val === 'file') return 'Image'
      if (val === 'title-and-text') return 'Title and Text'
      if (val === 'short-answer') return 'Short Answer Field'
      if (val === 'long-answer') return 'Long Answer Field'
      if (val === 'multi-select') return 'Selection List Field (Add-Ons)'
      if (val === 'single-select') return 'Single Checkbox Field'
      return '-'
    },
  },
}
</script>

<style scoped>
  .timeline-variant-info .timeline-item-point {
    background-color: #00cfe8 !important;
  }
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
</style>
